import * as React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import { MDXProvider } from '@mdx-js/react';

import Layout from './layout';
import { Query } from '../../types/graphql-types';

// utils functions

// Scroll to Page top.
const scrollToTop = () => {
  if (typeof document === 'undefined') {
    return;
  }
  const scrollContents = document.getElementById('scroll-contents');
  if (scrollContents === null) {
    return;
  }
  scrollContents?.scrollTo({ top: 0, behavior: 'smooth' });
};

// no @types package
const MDXRenderer = require('gatsby-plugin-mdx/mdx-renderer');

const BlogPost = ({ data }: { data: Query }) => {
  if (!data?.mdx?.frontmatter) {
    throw new Error('Invalid data. data: ' + JSON.stringify(data));
  }

  const featuredImgSrc =
    data.mdx.frontmatter?.featuredImage.childImageSharp.gatsbyImageData;
  const sumImgPath =
    data.mdx.frontmatter.featuredImage.childImageSharp.fluid.srcWebp;

  // 冒頭の本文
  const excerpt = data.mdx.excerpt;

  // ツイッター本文
  const twitterText = encodeURI(
    `${data.mdx.frontmatter.title} | 黄金虫ブログ @ocknamo ${
      data.site?.siteMetadata?.siteUrl
    }${encodeURI(data.mdx.frontmatter.slug)}`
  );
  return (
    <main>
      <Layout
        pageTitle={data.mdx.frontmatter.title}
        imagePath={sumImgPath}
        description={excerpt}
      >
        <p className="text-yellow-700">Posted: {data.mdx.frontmatter.date}</p>
        <GatsbyImage
          className="
            w-full
            h-60
            sm:max-w-tabletmain
            sm:h-80
            lg:max-w-screen-md
            lg:h-96
            object-cover
            "
          alt="Hero image"
          image={featuredImgSrc}
        ></GatsbyImage>
        <div
          className="
          break-words
          w-full
          mb-8
          mx-4
          markdown-body
          sm:max-w-tabletmain
          lg:max-w-screen-md
        "
        >
          <div className="min-w-full flex flex-row-reverse">
            <div className="m-4 sm:mx-8 sm:my-6">
              <a
                href={'https://twitter.com/intent/tweet?text=' + twitterText}
                target="_blank"
                rel="noreferrer"
              >
                <img src="/twitter_blue.svg" width="30" height="30"></img>
              </a>
            </div>
          </div>
          <MDXRenderer frontmatter={data.mdx.frontmatter}>
            {data.mdx.body}
          </MDXRenderer>
        </div>
        <hr className="mb-4 w-full border-2" />
        <button className="text-gray-600" onClick={scrollToTop}>
          Scroll to top
        </button>
      </Layout>
    </main>
  );
};

export const query = graphql`
  query BlogPostBySlug($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      body
      excerpt
      frontmatter {
        date
        slug
        title
        featuredImage {
          childImageSharp {
            gatsbyImageData
            fluid(maxWidth: 400) {
              srcWebp
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

export default BlogPost;
